import { createRouter, createWebHistory } from "vue-router"; // createWebHashHistory createWebHistory
import Home from "../views/Home.vue";
import Mini from "../views/Mini.vue";
import Compact from "../views/Compact.vue";
import Loaders from "../views/Loaders.vue";
import Form from "../views/Form.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/mini",
    name: "Mini",
    component: Mini,
  },
  {
    path: "/compact",
    name: "Compact",
    component: Compact,
  },
  {
    path: "/loaders",
    name: "Loaders",
    component: Loaders,
  },
  {
    path: "/form",
    name: "Form",
    component: Form,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
