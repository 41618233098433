import Checkbox from "../components/Checkbox.vue";

export default {
  name: "PageLoaders",
  components: {
    Checkbox,
  },
  data() {
    return {
      windowWidth: window.innerWidth,

      animationDeviceA: false,
      animationDeviceB: false,
      animationDeviceC: false,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;

      // console.warn(window.innerWidth);

      if (window.innerWidth >= 500) {
        this.$store.commit("loadingSave", false);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    goToForm() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        this.$router.push({ name: "Form" });
      }, 500);
    },
    deviceClick(item) {
      if (item == "A") {
        this.animationDeviceA = true;
      }

      if (item == "B") {
        this.animationDeviceB = true;
      }

      if (item == "C") {
        this.animationDeviceC = true;
      }
    },
  },
  created() {},
};
