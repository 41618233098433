<template>
  <div class="page mini">
    <div class="row mobile">
      <div class="col full-width">
        <div class="row">
          <div class="col full-width">
            <div class="row">
              <div class="col title">MINI BAGRY</div>
            </div>
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDevice: animationDeviceA,
            }"
            v-on:click="deviceClick('A')"
          >
            <img alt="" src="../assets/images/PC17R--5.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">PC17R-5</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />685 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'mini', id: 'PC17R_5', name: 'PC17R-5' }"
            />
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDevice: animationDeviceB,
            }"
            v-on:click="deviceClick('B')"
          >
            <img alt="" src="../assets/images/PC24MR--5.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">PC24MR-5</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />745 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'mini', id: 'PC24MR_5', name: 'PC24MR-5' }"
            />
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDevice: animationDeviceC,
            }"
            v-on:click="deviceClick('C')"
          >
            <img alt="" src="../assets/images/PC35MR--5.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">PC35MR-5</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />895 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'mini', id: 'PC35MR_5', name: 'PC35MR-5' }"
            />
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDevice: animationDeviceD,
            }"
            v-on:click="deviceClick('D')"
          >
            <img alt="" src="../assets/images/PC55MR--5.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">PC55MR-5</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />1,245 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'mini', id: 'PC55MR_5', name: 'PC55MR-5' }"
            />
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDevice: animationDeviceE,
            }"
            v-on:click="deviceClick('E')"
          >
            <img alt="" src="../assets/images/PC58MR--5.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">PC58MR-5</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />1,485 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'mini', id: 'PC58MR_5', name: 'PC58MR-5' }"
            />
          </div>
        </div>

        <div class="row top">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button blue" v-on:click="goToForm()">CHCI NABÍDKU</div>
          </div>
          <div class="col width_20"></div>
        </div>

        <div class="row bottom">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button gray" v-on:click="goBack()">ZPĚT</div>
          </div>
          <div class="col width_20"></div>
        </div>
      </div>
    </div>
    <div class="row desktop">
      <div class="col">
        <div class="row">
          <div class="col image intro-image-row">
            <img alt="" src="../assets/images/image-main-desktop.png" />
          </div>
        </div>
        <div class="row">
          <div class="col devices">
            <div class="row">
              <div class="col title">MINI BAGRY</div>
            </div>
            <div class="row wrap">
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDeviceDesktop: animationDeviceA,
                    }"
                    v-on:click="deviceClick('A')"
                  >
                    <img alt="" src="../assets/images/PC17R--5.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        PC17R-5
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />685 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{ group: 'mini', id: 'PC17R_5', name: 'PC17R-5' }"
                    />
                  </div>
                </div>
              </div>
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDeviceDesktop: animationDeviceB,
                    }"
                    v-on:click="deviceClick('B')"
                  >
                    <img alt="" src="../assets/images/PC24MR--5.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        PC24MR-5
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />745 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'mini',
                        id: 'PC24MR_5',
                        name: 'PC24MR-5',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDeviceDesktop: animationDeviceC,
                    }"
                    v-on:click="deviceClick('C')"
                  >
                    <img alt="" src="../assets/images/PC35MR--5.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        PC35MR-5
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />895 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'mini',
                        id: 'PC35MR_5',
                        name: 'PC35MR-5',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDeviceDesktop: animationDeviceD,
                    }"
                    v-on:click="deviceClick('D')"
                  >
                    <img alt="" src="../assets/images/PC55MR--5.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        PC55MR-5
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />1,245 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'mini',
                        id: 'PC55MR_5',
                        name: 'PC55MR-5',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDeviceDesktop: animationDeviceE,
                    }"
                    v-on:click="deviceClick('E')"
                  >
                    <img alt="" src="../assets/images/PC58MR--5.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        PC58MR-5
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />1,485 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'mini',
                        id: 'PC58MR_5',
                        name: 'PC58MR-5',
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row top">
          <div class="col">
            <div class="button blue" v-on:click="goToForm()">CHCI NABÍDKU</div>
          </div>
        </div>

        <div class="row bottom">
          <div class="col">
            <div class="button gray" v-on:click="goBack()">ZPĚT</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Mini.js"></script>
