<template>
  <div class="page form">
    <div class="row mobile">
      <div class="col full-width">
        <div class="row">
          <div class="col col_left"></div>
          <div class="col col_mid text-left">Děkujeme za Vaši poptávku,</div>
          <div class="col col_right"></div>
        </div>

        <div class="row" v-if="$store.getters.getSelectMini.length > 0">
          <div class="col col_left"></div>
          <div class="col col_mid text-left">
            <div>mini bagrů Komatsu</div>
            <div
              v-for="(item, i) in $store.getters.getSelectMini"
              :key="i"
              class="selected-item"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="col col_right"></div>
        </div>

        <div class="row" v-if="$store.getters.getSelectCompact.length > 0">
          <div class="col col_left"></div>
          <div class="col col_mid text-left">
            <div>kompaktních bagrů Komatsu</div>
            <div
              v-for="(item, i) in $store.getters.getSelectCompact"
              :key="i"
              class="selected-item"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="col col_right"></div>
        </div>

        <div class="row" v-if="$store.getters.getSelectLoaders.length > 0">
          <div class="col col_left"></div>
          <div class="col col_mid text-left">
            <div>kompaktních nakladačů Komatsu</div>
            <div
              v-for="(item, i) in $store.getters.getSelectLoaders"
              :key="i"
              class="selected-item"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="col col_right"></div>
        </div>

        <div class="row">
          <div class="col col_left"></div>
          <div class="col col_mid text-left">
            prosíme o vyplnění formuláře,<br />
            děkujeme.
          </div>
          <div class="col col_right"></div>
        </div>

        <div class="row">
          <div class="col col_left"></div>
          <div class="col name col_mid text-left">
            <label for="name">Jméno a příjmení</label><br />
            <input v-model="name" />
            <div class="validation">
              <span v-if="invalid && name.length == 0" class="validation-name"
                >Zadejte prosím jméno</span
              >
            </div>
          </div>
          <div class="col col_right"></div>
        </div>
        <div class="row">
          <div class="col col_left"></div>
          <div class="col email col_mid text-left">
            <label for="name">E-mail</label><br />
            <input v-model="email" />
            <div class="validation">
              <span v-if="invalid && email.length == 0" class="validation-email"
                >Zadejte prosím e-mail</span
              >
            </div>
          </div>
          <div class="col col_right"></div>
        </div>
        <div class="row">
          <div class="col col_left"></div>
          <div class="col phone col_mid text-left">
            <label for="name">Telefon</label><br />
            <input v-model="phone" />
            <div class="validation">
              <span v-if="invalid && phone.length == 0" class="validation-phone"
                >Zadejte prosím telefon</span
              >
            </div>
          </div>
          <div class="col col_right"></div>
        </div>
        <div class="row">
          <div class="col col_left"></div>
          <div class="col note col_mid text-left">
            <label for="name">Dotaz / Poznámka</label><br />
            <textarea v-model="note"></textarea>
          </div>
          <div class="col col_right"></div>
        </div>

        <div class="row">
          <div class="col col_left"></div>
          <div class="col col_mid">
            <div
              v-if="!sended && !sending"
              class="button blue"
              v-on:click="send()"
            >
              ODESLAT
            </div>

            <div v-if="sending" class="button gray">ODESLAT</div>

            <div v-if="sended" class="sended-text">
              Děkujeme za Vaši poptávku, co nejdříve Vás kontaktuje náš
              produktový specialista.<br /><br />

              S pozdravem<br />
              <strong>KUHN-BOHEMIA a.s.</strong><br />
              <span>Čestlice 299, 251 01 Čestlice, Czech Republic</span>
            </div>
          </div>
          <div class="col col_right"></div>
        </div>

        <div class="row">
          <div class="col col_left"></div>
          <div class="col col_mid">
            <div class="button gray" v-on:click="goBack()">ZPĚT</div>
          </div>
          <div class="col col_right"></div>
        </div>
      </div>
    </div>
    <div class="row desktop">
      <div class="col form-wrapper">
        <div class="row">
          <div class="col full-size text-left">Děkujeme za Vaši poptávku,</div>
        </div>

        <div class="row" v-if="$store.getters.getSelectMini.length > 0">
          <div class="col col_mid text-left">
            <div>mini bagrů Komatsu</div>
            <div
              v-for="(item, i) in $store.getters.getSelectMini"
              :key="i"
              class="selected-item"
            >
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="row" v-if="$store.getters.getSelectCompact.length > 0">
          <div class="col col_mid text-left">
            <div>kompaktních bagrů Komatsu</div>
            <div
              v-for="(item, i) in $store.getters.getSelectCompact"
              :key="i"
              class="selected-item"
            >
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="row" v-if="$store.getters.getSelectLoaders.length > 0">
          <div class="col col_mid text-left">
            <div>kompaktních nakladačů Komatsu</div>
            <div
              v-for="(item, i) in $store.getters.getSelectLoaders"
              :key="i"
              class="selected-item"
            >
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="row separator">
          <div class="col full-size text-left">
            prosíme o vyplnění formuláře, děkujeme.
          </div>
        </div>

        <div class="row">
          <div class="col full-size name text-left">
            <label for="name">Jméno a příjmení</label><br />
            <input v-model="name" />
            <div class="validation">
              <span v-if="invalid && name.length == 0" class="validation-name"
                >Zadejte prosím jméno</span
              >
            </div>
          </div>
          <div class="col full-size email text-left">
            <label for="name">E-mail</label><br />
            <input v-model="email" />
            <div class="validation">
              <span v-if="invalid && email.length == 0" class="validation-email"
                >Zadejte prosím e-mail</span
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col full-size phone text-left">
            <label for="name">Telefon</label><br />
            <input v-model="phone" />
            <div class="validation">
              <span v-if="invalid && phone.length == 0" class="validation-phone"
                >Zadejte prosím telefon</span
              >
            </div>
          </div>
          <div class="col full-size note text-left">
            <label for="name">Dotaz / Poznámka</label><br />
            <textarea v-model="note"></textarea>
          </div>
        </div>

        <div class="row flex-center">
          <div class="col">
            <div
              v-if="!sended && !sending"
              class="button blue"
              v-on:click="send()"
            >
              ODESLAT
            </div>

            <div v-if="sending" class="button gray">ODESLAT</div>

            <div v-if="sended" class="sended-text">
              Děkujeme za Vaši poptávku, co nejdříve Vás kontaktuje náš
              produktový specialista.<br /><br />

              S pozdravem<br />
              <strong>KUHN-BOHEMIA a.s.</strong><br />
              <span>Čestlice 299, 251 01 Čestlice, Czech Republic</span>
            </div>
          </div>
        </div>

        <div class="row flex-center">
          <div class="col">
            <div class="button gray" v-on:click="goBack()">ZPĚT</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Form.js"></script>
