export default {
  name: "PageHome",
  components: {},
  data() {
    return {
      windowWidth: window.innerWidth,

      animationStart: false,
      animationDeviceA: false,
      animationDeviceB: false,
      animationDeviceC: false,

      isHoveringA: false,
      isHoveringB: false,
      isHoveringC: false,

      isHoveringAservice: false,
      isHoveringBservice: false,
      isHoveringCservice: false,

      isHoveringDeviceMobileA: false,
      isHoveringDeviceMobileB: false,
      isHoveringDeviceMobileC: false,

      isHoveringDeviceDesktopA: false,
      isHoveringDeviceDesktopB: false,
      isHoveringDeviceDesktopC: false,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;

      // console.warn(window.innerWidth);

      if (window.innerWidth >= 500) {
        this.$store.commit("loadingSave", false);
      }
    },
    open() {
      this.animationStart = true;

      setTimeout(() => {
        this.$store.commit("loadingSave", false);
      }, 500);
    },
    deviceClick(item) {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        if (item == "A") {
          this.animationDeviceA = true;
          this.$router.push({ name: "Mini" });
        }

        if (item == "B") {
          this.animationDeviceB = true;
          this.$router.push({ name: "Compact" });
        }

        if (item == "C") {
          this.animationDeviceC = true;
          this.$router.push({ name: "Loaders" });
        }
      }, 500);
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    if (this.windowWidth >= 500) {
      this.$store.commit("loadingSave", false);
    }
  },
  created() {
    setTimeout(() => {
      this.$store.commit("loadingSave", false);
    }, 2000);
  },
};
