<template>
  <div class="checkbox" v-on:click="onChange()">
    <template v-if="!state">
      <img alt="" src="../assets/images/check-box-off.svg" />
    </template>
    <template v-if="state">
      <img alt="" src="../assets/images/check-box-on.svg" />
    </template>
  </div>
</template>

<script src="./Checkbox.js"></script>
