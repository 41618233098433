import { createStore } from "vuex";

export default createStore({
  state: {
    select: [],
    loading: true,
  },
  mutations: {
    selectAdd(state, value) {
      state.select.push(value);
    },
    selectSave(state, items) {
      state.select = items;
    },
    loadingSave(state, loading) {
      state.loading = loading;
    },
  },
  actions: {},
  modules: {},
  getters: {
    getSelect: (state) => {
      return state.select;
    },
    getSelectMini: (state) => {
      return state.select.filter((x) => x.group == "mini");
    },
    getSelectCompact: (state) => {
      return state.select.filter((x) => x.group == "compact");
    },
    getSelectLoaders: (state) => {
      return state.select.filter((x) => x.group == "loaders");
    },

    getLoading: (state) => {
      return state.loading;
    },
  },
});
