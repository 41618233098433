<template>
  <div class="page compact">
    <div class="row mobile">
      <div class="col full-width">
        <div class="row">
          <div class="col full-width">
            <div class="row">
              <div class="col title">KOMPAKTNÍ BAGRY</div>
            </div>
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDeviceWidth: animationDeviceA,
            }"
            v-on:click="deviceClick('A')"
          >
            <img alt="" src="../assets/images/PC80MR--5.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">PC80MR-5</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />1,795 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'compact', id: 'PC80MR_5', name: 'PC80MR-5' }"
            />
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDeviceWidth: animationDeviceB,
            }"
            v-on:click="deviceClick('B')"
          >
            <img alt="" src="../assets/images/PC88MR--11.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">PC88MR-11</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />1,995 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'compact', id: 'PC88MR_11', name: 'PC88MR-11' }"
            />
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDeviceWidth: animationDeviceC,
            }"
            v-on:click="deviceClick('C')"
          >
            <img alt="" src="../assets/images/PW98MR--11.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">PW98MR-11</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />2,995 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'compact', id: 'PW98MR_11', name: 'PW98MR-11' }"
            />
          </div>
        </div>

        <div class="row top">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button blue" v-on:click="goToForm()">CHCI NABÍDKU</div>
          </div>
          <div class="col width_20"></div>
        </div>

        <div class="row bottom">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button gray" v-on:click="goBack()">ZPĚT</div>
          </div>
          <div class="col width_20"></div>
        </div>
      </div>
    </div>
    <div class="row desktop">
      <div class="col">
        <div class="row">
          <div class="col image intro-image-row">
            <img alt="" src="../assets/images/image-main-desktop.png" />
          </div>
        </div>
        <div class="row">
          <div class="col devices">
            <div class="row">
              <div class="col title">KOMPAKTNÍ BAGRY</div>
            </div>
            <div class="row wrap">
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDevice: animationDeviceA,
                    }"
                    v-on:click="deviceClick('A')"
                  >
                    <img alt="" src="../assets/images/PC80MR--5.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        PC80MR-5
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />1,795 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'compact',
                        id: 'PC80MR_5',
                        name: 'PC80MR-5',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDevice: animationDeviceB,
                    }"
                    v-on:click="deviceClick('B')"
                  >
                    <img alt="" src="../assets/images/PC88MR--11.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        PC88MR-11
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />1,995 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'compact',
                        id: 'PC88MR_11',
                        name: 'PC88MR-11',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDevice: animationDeviceC,
                    }"
                    v-on:click="deviceClick('C')"
                  >
                    <img alt="" src="../assets/images/PW98MR--11.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        PW98MR-11
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />2,995 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'compact',
                        id: 'PW98MR_11',
                        name: 'PW98MR-11',
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row top">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button blue" v-on:click="goToForm()">CHCI NABÍDKU</div>
          </div>
          <div class="col width_20"></div>
        </div>

        <div class="row bottom">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button gray" v-on:click="goBack()">ZPĚT</div>
          </div>
          <div class="col width_20"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Compact.js"></script>
