<template>
  <div class="page home">
    <div class="row mobile">
      <template v-if="$store.getters.getLoading">
        <div
          :class="{
            col: true,
            'full-width': true,
            'animation-start': animationStart,
          }"
        >
          <div class="row">
            <div class="col title">PRO ŽIVNOSTNÍKY A PODNIKATELE</div>
          </div>
          <div class="row intro-image-row" v-on:click="open()">
            <div class="col image">
              <img alt="" src="../assets/images/image-main.png" />
            </div>
          </div>
          <div class="row"></div>
        </div>
      </template>
      <template v-if="!$store.getters.getLoading">
        <div class="col full-width">
          <div class="row">
            <div class="col full-width">
              <div class="row">
                <div class="col title">MINI BAGRY</div>
              </div>
              <div class="row box">
                <div
                  :class="{
                    col: true,
                    device: true,
                    'double-size': true,
                    animateDeviceWidth: isHoveringDeviceMobileA,
                  }"
                  @mouseover="isHoveringDeviceMobileA = true"
                  @mouseout="isHoveringDeviceMobileA = false"
                  v-on:click="deviceClick('A')"
                >
                  <img alt="" src="../assets/images/PC58MR--5.png" />
                </div>
                <div class="col double-size">
                  <div class="row attribute-row">
                    <div class="col icon-attribute">
                      <img alt="" src="../assets/images/icon-weigth.svg" />
                    </div>
                    <div class="col attribute">
                      Hmotnost:<br />0,9 - 6,0 tun
                    </div>
                  </div>
                  <div class="row attribute-row">
                    <div class="col icon-attribute">
                      <img alt="" src="../assets/images/icon-extend.svg" />
                    </div>
                    <div class="col attribute">
                      Hloubkový dosah:<br />2.250 - 4.130 mm
                    </div>
                  </div>
                  <div class="row">
                    <div class="button blue" v-on:click="deviceClick('A')">
                      CHCI NABÍDKU
                    </div>
                  </div>
                  <div class="row">
                    <a
                      href="https://kuhnbohemia.cz/cs/building-machines/location-finder"
                      target="_blank"
                      ><div class="button yellow">POTŘEBUJI SERVIS</div></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col full-width">
              <div class="row">
                <div class="col title">KOMPAKTNÍ BAGRY</div>
              </div>
              <div class="row box">
                <div
                  :class="{
                    col: true,
                    device: true,
                    'double-size': true,
                    animateDeviceWidth: isHoveringDeviceMobileB,
                  }"
                  @mouseover="isHoveringDeviceMobileB = true"
                  @mouseout="isHoveringDeviceMobileB = false"
                  v-on:click="deviceClick('B')"
                >
                  <img alt="" src="../assets/images/PC88MR--11.png" />
                </div>
                <div class="col double-size">
                  <div class="row attribute-row">
                    <div class="col icon-attribute">
                      <img alt="" src="../assets/images/icon-weigth.svg" />
                    </div>
                    <div class="col attribute">
                      Hmotnost:<br />4,98 - 9,0 tun
                    </div>
                  </div>
                  <div class="row attribute-row">
                    <div class="col icon-attribute">
                      <img alt="" src="../assets/images/icon-extend.svg" />
                    </div>
                    <div class="col attribute">
                      Hloubkový dosah:<br />4.335 - 4.620 mm
                    </div>
                  </div>
                  <div class="row">
                    <div class="button blue" v-on:click="deviceClick('B')">
                      CHCI NABÍDKU
                    </div>
                  </div>
                  <div class="row">
                    <a
                      href="https://kuhnbohemia.cz/cs/building-machines/location-finder"
                      target="_blank"
                      ><div class="button yellow">POTŘEBUJI SERVIS</div></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col full-width">
              <div class="row">
                <div class="col title">KOMPAKTNÍ NAKLADAČE</div>
              </div>
              <div class="row box">
                <div
                  :class="{
                    col: true,
                    device: true,
                    'double-size': true,
                    animateDeviceWidth: isHoveringDeviceMobileC,
                  }"
                  @mouseover="isHoveringDeviceMobileC = true"
                  @mouseout="isHoveringDeviceMobileC = false"
                  v-on:click="deviceClick('C')"
                >
                  <img alt="" src="../assets/images/WA100M--8.png" />
                </div>
                <div class="col double-size">
                  <div class="row attribute-row">
                    <div class="col icon-attribute">
                      <img alt="" src="../assets/images/icon-weigth.svg" />
                    </div>
                    <div class="col attribute">
                      Hmotnost:<br />4,98 - 9,0 tun
                    </div>
                  </div>
                  <div class="row attribute-row">
                    <div class="col icon-attribute">
                      <img alt="" src="../assets/images/icon-loader.svg" />
                    </div>
                    <div class="col attribute">
                      Objem lopaty:<br />0,75 - 1.8 m3
                    </div>
                  </div>
                  <div class="row">
                    <div class="button blue" v-on:click="deviceClick('C')">
                      CHCI NABÍDKU
                    </div>
                  </div>
                  <div class="row">
                    <a
                      href="https://kuhnbohemia.cz/cs/building-machines/location-finder"
                      target="_blank"
                      ><div class="button yellow">POTŘEBUJI SERVIS</div></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="row desktop">
      <div class="col">
        <div class="row">
          <div class="col image intro-image-row">
            <img alt="" src="../assets/images/image-main-desktop.png" />
          </div>
        </div>
        <div class="row">
          <div class="col devices">
            <div class="row devices-wrapper">
              <div class="col third">
                <div class="row">
                  <div class="col full-width">
                    <div class="row">
                      <div class="col title">MINI BAGRY</div>
                    </div>
                    <div class="row box">
                      <div class="col" :style="{ width: '100%' }">
                        <div
                          :class="{
                            row: true,
                            device: true,
                            animateDeviceDesktop: isHoveringDeviceDesktopA,
                          }"
                          @mouseover="isHoveringDeviceDesktopA = true"
                          @mouseout="isHoveringDeviceDesktopA = false"
                          v-on:click="deviceClick('A')"
                        >
                          <img alt="" src="../assets/images/PC58MR--5.png" />
                        </div>
                        <div class="row attribute-row">
                          <div class="col icon-attribute">
                            <img
                              alt=""
                              src="../assets/images/icon-weigth.svg"
                            />
                          </div>
                          <div class="col attribute">
                            Hmotnost: 0,9 - 6,0 tun
                          </div>
                        </div>
                        <div class="row attribute-row">
                          <div class="col icon-attribute">
                            <img
                              alt=""
                              src="../assets/images/icon-extend.svg"
                            />
                          </div>
                          <div class="col attribute">
                            Hloubkový dosah: 2.250 - 4.130 mm
                          </div>
                        </div>
                        <div class="row">
                          <div
                            v-on:click="deviceClick('A')"
                            :class="{
                              button: true,
                              blue: true,
                              isHoveringA: isHoveringA,
                            }"
                            @mouseover="isHoveringA = true"
                            @mouseout="isHoveringA = false"
                          >
                            CHCI NABÍDKU
                          </div>
                        </div>
                        <div class="row">
                          <a
                            href="https://kuhnbohemia.cz/cs/building-machines/location-finder"
                            target="_blank"
                            ><div
                              :class="{
                                button: true,
                                yellow: true,
                                isHoveringAservice: isHoveringAservice,
                              }"
                              @mouseover="isHoveringAservice = true"
                              @mouseout="isHoveringAservice = false"
                            >
                              POTŘEBUJI SERVIS
                            </div></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col third">
                <div class="row">
                  <div class="col full-width">
                    <div class="row">
                      <div class="col title">KOMPAKTNÍ BAGRY</div>
                    </div>
                    <div class="row box">
                      <div class="col" :style="{ width: '100%' }">
                        <div
                          :class="{
                            row: true,
                            device: true,
                            animateDeviceDesktop: isHoveringDeviceDesktopB,
                          }"
                          @mouseover="isHoveringDeviceDesktopB = true"
                          @mouseout="isHoveringDeviceDesktopB = false"
                          v-on:click="deviceClick('B')"
                        >
                          <img alt="" src="../assets/images/PC88MR--11.png" />
                        </div>
                        <div class="row attribute-row">
                          <div class="col icon-attribute">
                            <img
                              alt=""
                              src="../assets/images/icon-weigth.svg"
                            />
                          </div>
                          <div class="col attribute">
                            Hmotnost: 4,98 - 9,0 tun
                          </div>
                        </div>
                        <div class="row attribute-row">
                          <div class="col icon-attribute">
                            <img
                              alt=""
                              src="../assets/images/icon-extend.svg"
                            />
                          </div>
                          <div class="col attribute">
                            Hloubkový dosah: 4.335 - 4.620 mm
                          </div>
                        </div>
                        <div class="row">
                          <div
                            v-on:click="deviceClick('B')"
                            :class="{
                              button: true,
                              blue: true,
                              isHoveringB: isHoveringB,
                            }"
                            @mouseover="isHoveringB = true"
                            @mouseout="isHoveringB = false"
                          >
                            CHCI NABÍDKU
                          </div>
                        </div>
                        <div class="row">
                          <a
                            href="https://kuhnbohemia.cz/cs/building-machines/location-finder"
                            target="_blank"
                            ><div
                              :class="{
                                button: true,
                                yellow: true,
                                isHoveringBservice: isHoveringBservice,
                              }"
                              @mouseover="isHoveringBservice = true"
                              @mouseout="isHoveringBservice = false"
                            >
                              POTŘEBUJI SERVIS
                            </div></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col third">
                <div class="row">
                  <div class="col full-width">
                    <div class="row">
                      <div class="col title">KOMPAKTNÍ NAKLADAČE</div>
                    </div>
                    <div class="row box">
                      <div class="col" :style="{ width: '100%' }">
                        <div
                          :class="{
                            row: true,
                            device: true,
                            animateDeviceDesktop: isHoveringDeviceDesktopC,
                          }"
                          @mouseover="isHoveringDeviceDesktopC = true"
                          @mouseout="isHoveringDeviceDesktopC = false"
                          v-on:click="deviceClick('C')"
                        >
                          <img alt="" src="../assets/images/WA100M--8.png" />
                        </div>
                        <div class="row attribute-row">
                          <div class="col icon-attribute">
                            <img
                              alt=""
                              src="../assets/images/icon-weigth.svg"
                            />
                          </div>
                          <div class="col attribute">
                            Hmotnost: 4,98 - 9,0 tun
                          </div>
                        </div>
                        <div class="row attribute-row">
                          <div class="col icon-attribute">
                            <img
                              alt=""
                              src="../assets/images/icon-loader.svg"
                            />
                          </div>
                          <div class="col attribute">
                            Objem lopaty: 0,75 - 1,8 m3
                          </div>
                        </div>
                        <div class="row">
                          <div
                            v-on:click="deviceClick('C')"
                            :class="{
                              button: true,
                              blue: true,
                              isHoveringC: isHoveringC,
                            }"
                            @mouseover="isHoveringC = true"
                            @mouseout="isHoveringC = false"
                          >
                            CHCI NABÍDKU
                          </div>
                        </div>
                        <div class="row">
                          <a
                            href="https://kuhnbohemia.cz/cs/building-machines/location-finder"
                            target="_blank"
                            ><div
                              :class="{
                                button: true,
                                yellow: true,
                                isHoveringCservice: isHoveringCservice,
                              }"
                              @mouseover="isHoveringCservice = true"
                              @mouseout="isHoveringCservice = false"
                            >
                              POTŘEBUJI SERVIS
                            </div></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Home.js"></script>
