<template>
  <div id="footer">
    <div class="row">
      <div class="col">
        <img alt="Vue logo" src="../assets/images/logo-kuhn.png" />
      </div>
    </div>
    <div class="row">
      <div class="col">č.p. 299, 251 01 Čestlice, Česká republika</div>
    </div>
    <div class="row">
      <div class="col">
        <a href="tel:+420 212 200 200">
          <img src="../assets/images/icon-telephone.svg" />
        </a>
      </div>
      <div class="col phone">
        <span><a href="tel:+420 212 200 200">+420 212 200 200</a></span>
      </div>
    </div>
  </div>
</template>

<script src="./Footer.js"></script>
