export default {
  name: "Checkbox",
  props: {
    model: Object,
  },
  data() {
    return {
      state: false,
    };
  },
  methods: {
    onChange() {
      this.state = !this.state;

      if (this.state) {
        this.$store.commit("selectAdd", this.model);
      } else {
        const items = this.$store.getters.getSelect.filter(
          (x) => x.id != this.model.id
        );
        this.$store.commit("selectSave", items);
      }
    },
  },
  created() {
    this.state = this.$store.getters.getSelect.find(
      (x) => x.id == this.model.id
    )
      ? true
      : false;
  },
};
