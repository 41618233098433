<template>
  <div class="page loaders">
    <div class="row mobile">
      <div class="col full-width">
        <div class="row">
          <div class="col full-width">
            <div class="row">
              <div class="col title">KOMPAKTNÍ NAKLADAČE</div>
            </div>
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDeviceWidth: animationDeviceA,
            }"
            v-on:click="deviceClick('A')"
          >
            <img alt="" src="../assets/images/WA70M--8.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">WA70M-8E0</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />1,145 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'loaders', id: 'WA70M_8E0', name: 'WA70M-8E0' }"
            />
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDeviceWidth: animationDeviceB,
            }"
            v-on:click="deviceClick('B')"
          >
            <img alt="" src="../assets/images/WA80M--8.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">WA80M-8E0</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />1,395 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{ group: 'loaders', id: 'WA80M_8E0', name: 'WA80M-8E0' }"
            />
          </div>
        </div>

        <div class="row box">
          <div
            :class="{
              col: true,
              device: true,
              col_left: true,
              animateDeviceWidth: animationDeviceC,
            }"
            v-on:click="deviceClick('C')"
          >
            <img alt="" src="../assets/images/WA100M--8.png" />
          </div>
          <div class="col col_mid">
            <div class="row">
              <div class="col full-width text-left item-title">WA100M-8E0</div>
            </div>
            <div class="row">
              <div class="col full-width text-left item-price">
                cena již od<br />1,945 tis Kč<br />
                bez DPH
              </div>
            </div>
          </div>
          <div class="col col_right">
            <Checkbox
              :model="{
                group: 'loaders',
                id: 'WA100M_8E0',
                name: 'WA100M-8E0',
              }"
            />
          </div>
        </div>

        <div class="row top">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button blue" v-on:click="goToForm()">CHCI NABÍDKU</div>
          </div>
          <div class="col width_20"></div>
        </div>

        <div class="row bottom">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button gray" v-on:click="goBack()">ZPĚT</div>
          </div>
          <div class="col width_20"></div>
        </div>
      </div>
    </div>
    <div class="row desktop">
      <div class="col">
        <div class="row">
          <div class="col image intro-image-row">
            <img alt="" src="../assets/images/image-main-desktop.png" />
          </div>
        </div>
        <div class="row">
          <div class="col devices">
            <div class="row">
              <div class="col title">KOMPAKTNÍ NAKLADAČE</div>
            </div>
            <div class="row wrap">
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDeviceWidth: animationDeviceA,
                    }"
                    v-on:click="deviceClick('A')"
                  >
                    <img alt="" src="../assets/images/WA70M--8.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        WA70M-8E0
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />1,145 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'loaders',
                        id: 'WA70M_8E0',
                        name: 'WA70M-8E0',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDeviceWidth: animationDeviceB,
                    }"
                    v-on:click="deviceClick('B')"
                  >
                    <img alt="" src="../assets/images/WA80M--8.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        WA80M-8E0
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />1,395 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'loaders',
                        id: 'WA80M_8E0',
                        name: 'WA80M-8E0',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col box">
                <div class="row">
                  <div
                    :class="{
                      col: true,
                      device: true,
                      col_left: true,
                      animateDeviceWidth: animationDeviceC,
                    }"
                    v-on:click="deviceClick('C')"
                  >
                    <img alt="" src="../assets/images/WA100M--8.png" />
                  </div>
                  <div class="col col_mid">
                    <div class="row">
                      <div class="col full-width text-left item-title">
                        WA100M-8E0
                      </div>
                    </div>
                    <div class="row">
                      <div class="col full-width text-left item-price">
                        cena již od<br />1,945 tis Kč<br />
                        bez DPH
                      </div>
                    </div>
                  </div>
                  <div class="col col_right">
                    <Checkbox
                      :model="{
                        group: 'loaders',
                        id: 'WA100M_8E0',
                        name: 'WA100M-8E0',
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row top">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button blue" v-on:click="goToForm()">CHCI NABÍDKU</div>
          </div>
          <div class="col width_20"></div>
        </div>

        <div class="row bottom">
          <div class="col width_20"></div>
          <div class="col width_60">
            <div class="button gray" v-on:click="goBack()">ZPĚT</div>
          </div>
          <div class="col width_20"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Loaders.js"></script>
